<template>
  <div>
    <el-col :span="4" class="item" v-if="productType=='FreeMailGoods'">
      <div class="imgWrap" @click="jumpDetail(info)">
        <img :alt="info.name | defaultName" :src="info.pic?info.pic + picSuffix : defaultImage" />
      </div>
      <h3 class="shopName" :title="info.name">{{ info.name | defaultName }}</h3>
      <div class="priceBox">
        <span class="price">{{info.price  | capitalize}}</span>
        <!-- <del class="delPrice">{{info.originalPrice  | capitalize}}</del> -->
      </div>
      <p class="msg">热销中</p>
    </el-col>
  </div>

</template>
<style lang="less" scoped>
  @import "~style/index.less";

  .item {
    background: #fff;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 10px;

    .imgWrap {
      padding: 15px 0;
      overflow: hidden;

      img {
        display: inline-block;
        width: 176px;
        height: 176px;
        margin: 0 auto;
        cursor: pointer;

        &:hover {
          box-shadow: 10px 6px 6px -4px #dedada;
          border-radius: 7px;
        }
      }
    }

    .shopName {
      height: 22px;
      color: #333;
      font-size: 15px;
      line-height: 22px;
      font-weight: 600;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      // overflow:hidden;
      // text-overflow: ellipsis;
      // display: -webkit-box;
      // -webkit-line-clamp:2;
      // -webkit-box-orient: vertical;
    }

    .priceBox {
      margin-top: 5px;
      height: 26px;
      line-height: 26px;
      font-size: 12px;
      color: #999;

      // .labelBox {
      //     display: inline-block;
      //   background: #ff3333;
      //   padding: 0 5px;
      //   color: #fff;
      //   text-align: center;
      //   line-height:20px;
      //   position: relative;
      //   .arrow-right {
      //         position: absolute;
      //         right: -4px;
      //         width: 6px;
      //         height: 6px;
      //         top: 6px;
      //         background: #ff3333;
      //         border-top: 2px solid #ff3333;
      //         border-right: 2px solid #ff3333;
      //         transform: rotate(45deg);
      //   }
      // }
      .price {
        color: #ff3333;
        font-size: 16px;
        font-weight: bold;
        display: inline-block;
      }

      .delPrice {
        padding-left: 10px;
        color: #929598;
        font-size: 12px;
      }

      .num {
        color: #929598;
        font-size: 12px;
        float: right;
      }
    }

    .msg {
      color: #929598;
      font-size: 12px;
      text-indent: 3px;
      line-height: 20px;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      cursor: pointer;
    }
  }
</style>

<script>
  /**
   * ?此组件目前搭配comboLi使用
   */
  import defaultImage from "@/assets/defaultImage.png";
  export default {
    name: "ComboItemLi",
    data() {
      return {
        picSuffix: localStorage.getItem("productPic"),
        currentIndex: 0,
        //价格框显示隐藏
        status: false,
        num: 0,
        // pics: 1,
        defaultImage: defaultImage,
        promotionMap: "",
      };
    },
    props: {
      info: {
        type: Object,
      },
      productType: {
        type: String,
      },
    },
    methods: {
      //跳转商品详情
      jumpDetail(info) {
        // console.log('info: ', info);
        this.$router.push({
          name: "FreeMailDetail",
          query: {
            id: info.id,
            promotionId: info.promotionId,
            isRecentExpiration: info.isRecentExpiration
          },
        });
      },

    },
    created() {
      // console.log(this.info)
      if (this.productType == 'setMeal') {
        this.url = this.info.albumPics.split(",")[0]
      }
      // console.log(this.info);
    },
    updated() {},
  };
</script>